$panel-border-color: #c5c6e8;
$panel-border-radius: 10px;

$protists-color: #714486;
$plants-color: #407253;
$bacteria-color: #70708d;
$fungi-color: #725a4c;
$metazoa-color: #015365;
$ensembl-modern-color: #2378a9; // or #3d78a5 ?

.home__division-panel {
  display: grid;
  grid-template-rows: [top] 62px [middle] 1fr [bottom] 60px;
  border: 1px solid $panel-border-color;
  border-radius: $panel-border-radius;
  height: 300px;
  max-width: 460px;
  text-align: center;
}

.home__division-panel_protists {
  grid-area: protists;
}

.home__division-panel_plants {
  grid-area: plants;
}

.home__division-panel_bacteria {
  grid-area: bacteria;
}

.home__division-panel_fungi {
  grid-area: fungi;
}

.home__division-panel_metazoa {
  grid-area: metazoa;
}

.home__division-panel-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0 24px;

  img {
    height: 32px;
  }
}

.home__division-panel-logo-text {
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  margin-top: 4px;

  &_plants {
    color: $plants-color;
  }
  &_protists {
    color: $protists-color;
  }
  &_bacteria {
    color: $bacteria-color;
  }
  &_fungi {
    color: $fungi-color;
  }
  &_metazoa {
    color: $metazoa-color;
  }
}

.home__panel-species {
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-column-gap: 10px;
  margin-left: 50px;
  text-align: left;
  
  & + & {
    margin-top: 16px;
  }
}

.home__panel-species-image {
  img {
    max-width: 100%;
  }
}

.home__panel-goto-button {
  align-self: start;
  display: inline-block;
  justify-self: center;
  padding: 12px 30px;
  border-radius: 10px;
  text-decoration: none;

  &, &:visited {
    color: white;
  }
  
  &_protists {
    background-color: $protists-color;

    &:hover {
      background-color: darken($protists-color, 5%);
    }
  }

  &_plants {
    background-color: $plants-color;

    &:hover {
      background-color: darken($plants-color, 5%);
    }
  }

  &_bacteria {
    background-color: $bacteria-color;

    &:hover {
      background-color: darken($bacteria-color, 5%);
    }
  }

  &_fungi {
    background-color: $fungi-color;

    &:hover {
      background-color: darken($fungi-color, 5%);
    }
  }

  &_metazoa {
    background-color: $metazoa-color;

    &:hover {
      background-color: darken($metazoa-color, 5%);
    }
  }
}

.home__modern-panel {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
  max-width: 460px;
}

.home__modern-panel_covid {
  grid-area: sars;
}

.home__modern-panel_rapid {
  grid-area: rapid-release;
}


.home__modern-panel-header {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 24px;
  background-color: $ensembl-modern-color;
  border-top-left-radius: $panel-border-radius;
  border-top-right-radius: $panel-border-radius;
}

.home__modern-panel-logo {
  display: grid;
  grid-template-areas:
      "image heading"
      ". subheading";
  grid-template-columns: min-content  min-content;
  
  &-image {
    grid-area: image;

    img {
      height: 32px;
    }
  }

  &-heading {
    grid-area: heading;
    color: white;
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    align-self: center;
  }

  &-subheading {
    grid-area: subheading;
    color: white;
    font-size: 10px;
    font-weight: 300;
    text-align: right;
    transform: translateY(-60%);

    .home__modern-panel_rapid & {
      font-style: italic;
    }
  }
}

.home__modern-panel-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-left-color: $panel-border-color;
  border-right-color: $panel-border-color;
  border-bottom-color: $panel-border-color;
  border-bottom-left-radius: $panel-border-radius;
  border-bottom-right-radius: $panel-border-radius;
  padding: 0 26px 0 34px;
}

.home__modern-panel-covid-body-logo {
  display: flex;
  align-items: center;
}

.home__modern-panel-covid-image {
  width: 36px;
}

.home__modern-panel-covid-label {
  font-weight: bold;
}

.home__modern-panel-goto-button {
  text-decoration: none;
  background-color: $ensembl-modern-color;
  border-radius: 5px;
  padding: 4px 8px;

  &, &:visited {
    color: white;
  }
}

.home__search-panel {
  grid-column: search;
  height: 58px;
  border: 1px solid $panel-border-color;
  background-color: #ccd4e5;
  padding-right: 30px;

  form {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;

    input {
      width: 40%;
    }

    label {
      margin-right: 24px;
    }
  }

  @media (max-width: 899px) {
    grid-column: 1/2;
    grid-row: search;
  }
}

.home__goto-search-button {
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #323366;
  color: white;
  padding: 4px 8px;
  margin-left: 14px;
  cursor: pointer;
}

.home__blog-panel {
  grid-column: blog;
  align-self: center;
  display: flex;
  align-items: center;

  @media (max-width: 899px) {
    grid-column: 1/2;
    grid-row: blog;
    margin-left: 34px;
  }
} 

.home__blog-panel-image {
  height: 30px;
  margin-right: 20px;
}
