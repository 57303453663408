@import '../shared/variables.scss';
@import '../shared/breakpoints.scss';

main {
  width: 100%;
  max-width: $three-home-cards-width;
  min-width: $one-home-card-width-padded;
  padding: 0 $side-padding;
  margin: 0 auto;
}

.home-top-grid {
  display: grid;
  padding-top: 20px;
  
  @media (min-width: 900px) {
    grid-template-columns: [blog] 1fr [search] 2fr;
    grid-column-gap: 32px;
  }

  @media (max-width: 899px) {
    grid-template-rows: [search] auto [blog] auto;
    grid-row-gap: 20px;
  }
}

.home-bottom-grid {
  display: grid;
  height: 100%;
  padding-top: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "sars plants metazoa"
    "rapid-release plants metazoa"
    "protists fungi bacteria"
    "protists fungi bacteria";
  grid-template-rows: repeat(4, 140px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: start;

  @media #{$breakpoints-middle} {
    max-width: $two-home-cards-width;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "sars plants"
      "rapid-release plants"
      "metazoa protists"
      "metazoa protists"
      "fungi bacteria"
      "fungi bacteria";
  }

  @media #{$breakpoints-small-all} {
    max-width: $home-card-width;
    margin: 0 auto;
    grid-template-columns: 1fr;
    grid-template-rows: 140px 140px repeat(5, 300px);
    grid-template-areas:
      "sars"
      "rapid-release"
      "plants"
      "metazoa"
      "protists"
      "fungi"
      "bacteria";
  }
}
